import React from 'react';
import style from './ChoicePage.module.css';
import { useNavigate } from 'react-router-dom';

import AIIcon from '../../assets/AIIcon.svg'
import PlusIcon from '../../assets/PlusIcon.svg'

import ChoiceCard from '../../components/ChoiceCard/ChoiceCard';


const ChoicePage = () => {
	const navigate = useNavigate()

	return (
		<div className={style.ChoicePage}>
			<div className={style.Heading}>
				<span className={style.ChoiceHeading}>Make a routine</span>
				<span className={style.ChoiceText}>You can either generate or create.</span>
			</div>

			<div className={style.ChoiceCardWrapper}>
				<ChoiceCard
					icon={AIIcon}
					heading='Generate Routine'
					text='Generate a personalized routine'
					click={() => navigate('/interests')}
				/>

				<ChoiceCard
					icon={PlusIcon}
					heading='Create Routine'
					text='Create your own routine from scratch'
					click={() => navigate('/add-routine')}
				/>
			</div>
		</div>
	)
}

export default ChoicePage