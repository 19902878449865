import React from 'react';
import style from './StrydCardSmall.module.css';
import timeIcon from '../../assets/timeIcon.svg'
import { Link } from 'react-router-dom';
import {
	useUser,
} from "@clerk/clerk-react";
import toast from 'react-simple-toasts';



const StrydCardSmall = props => {
	const { user } = useUser()


	return (
		<div className={style.StrydCardSmall}
		// onClick={props.click()}
		>
			<div className={style.RoutineNameWrapper}>
				<span className={style.RoutineName}>{props.name}</span>
				<span className={style.RoutineDetail}>
					{/* <Link to={`/profile/${props.userID}`} className={style.RoutineDetail}> */}
					Created by {props.creator}
					{/* </Link> */}
				</span>
			</div>

			<div className={style.RoutineAtrributesWrapper}>
				<div className={style.AttributesWrapper}>
					<img src={timeIcon} alt="" className={style.TimeIcon} />
					<span className={style.TimeText}>{props.time} mins</span>
				</div>
			</div>
		</div>
	)
}

export default StrydCardSmall;