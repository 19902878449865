import React from 'react';
import style from './ProgressBar.module.css';

const ProgressBar = props => {
	const { progress } = props;

	return (
		<div className={style.ProgressBarWrapper}>
			<div className={style.ProgressBar}>
				<div className={style.ProgressFill} style={{ width: `${progress}%` }}></div>
			</div>
		</div>
	)
}

export default ProgressBar;