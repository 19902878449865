import React from 'react';
import style from './Rank.module.css';

const Rank = (props) => {
	return (
		<div className={style.RankWrapper}>
			<span>{props.name}</span>
			<span>{props.minScore} XP</span>
			<span>-</span>
			<span>{props.maxScore} XP</span>
		</div>
	)
}

export default Rank;