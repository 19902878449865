import React, { useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import style from './ProfilePage.module.css';
import StrydCardSmall from '../../components/StrydCardSmall/StrydCardSmall';
import spinner from '../../assets/Spinner.svg'
import Checkbox from '../../components/CheckBox/CheckBox';
import Tag from '../../components/Tag/Tag';
import rankData from '../../utils/ranks';
import findUserRank from '../../utils/findUserRank';
import RanksModal from '../../components/RanksModal/RanksModal';

import {
	useUser,
} from "@clerk/clerk-react";

// import {clerkClient} from '@clerk/clerk-react/server'


const ProfilePage = () => {
	const { user } = useUser()
	const navigate = useNavigate()
	const API_URL = process.env.REACT_APP_API_URL;

	const [stryds, setStryds] = useState({});
	const [dbUser, setDbUser] = useState(null);
	const [isRanksModalOpen, setIsRanksModalOpen] = useState(false)

	const getUser = useCallback(() => {
		if (user) {
			axios.get(`${API_URL}/users/${user.id}`)
				.then(res => (setDbUser(res.data[0])))
				.catch(err => console.log("Error:", err.message));
		}
	}, [API_URL, user]);

	const handleOpenRanksModal = () => {
		setIsRanksModalOpen(true)
	}

	const handleCloseRanksModal = () => {
		setIsRanksModalOpen(false)
	}

	useEffect(() => {
		if (user && user.id && !dbUser) {
			getUser();
		}
	}, [dbUser, getUser, user]);


	// console.log(clerkClient)
	return (
		<div className={style.ProfilePage}>

			{
				isRanksModalOpen && (
					<RanksModal
						onClose={handleCloseRanksModal}
						ranks={rankData}
					/>
				)
			}

			{user && dbUser ? <>
				<div className={style.ProfileInfoWrapper}>
					<div className={style.ProfileUserStats}>
						<div>
							<div className={style.ProfileInfo}>
								<img
									className={style.ProfilePicture}
									src={user.profileImageUrl}
									alt="" />


								<div className={style.ProfileInfoDetails}>
									<div className={style.ProfileInfoText}>
										<span className={style.ProfileInfoUsername}>
											{user.username}
										</span>

										<span className={style.ProfileRank}
											onClick={() => {
												handleOpenRanksModal()
											}}>
											{findUserRank(dbUser.totalXP, rankData)}
										</span>
									</div>


									<div className={style.ProfileAttributesWrapper}>
										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Followers
											</span>

											<span className={style.ProfileCount}>
												{dbUser.socials.followers.length}
											</span>
										</div>

										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Following
											</span>

											<span className={style.ProfileCount}>
												{dbUser.socials.followings.length}
											</span>
										</div>

										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Total XP
											</span>

											<span className={style.ProfileCount}>
												{dbUser.totalXP}
											</span>
										</div>
									</div>
								</div>

							</div>
						</div>

						<div>
							<div>
								<span>
									{
										dbUser ? (
											<div className={style.UserInterests}>
												{dbUser.interests && dbUser.interests.workoutInterests ? (
													dbUser.interests.workoutInterests.map((interest) => (
														<Tag label={interest} />
													))
												) : (
													<span className={style.EmptyText}>
														No workout interests
													</span>
												)}
											</div>
										) : (
											<span>Loading</span>
										)
									}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className={style.UserRoutines}>
					{/* Generated Routines */}
					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Generated Routines</span>
						{dbUser ? (
							dbUser.stryds && dbUser.stryds.routines ? (
								<div className={style.CardsContainer}>
									{dbUser.stryds.routines
										.filter((routine) => routine.routineCreationType === 'generated')
										.map((routine, index) => (
											<Link to={`/stryd/${user.id}/${routine.routineName}`} key={index}>
												<StrydCardSmall
													name={routine.routineName}
													time={routine.estimatedTimeMinutes}
													creator={user.username}
													userID={user.id}
													type={routine.routineCreationType}
												/>
											</Link>
										))
									}
								</div>
							) : (
								<span className={style.EmptyText}>No routines available</span>
							)
						) : (
							<img src={spinner} alt="Loading..." />
						)}
					</div>

					{/* Created Routines */}
					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Created Routines</span>
						{dbUser ? (
							dbUser.stryds && dbUser.stryds.routines ? (
								<div className={style.CardsContainer}>
									{dbUser.stryds.routines
										.filter((routine) => routine.routineCreationType === 'created')
										.map((routine, index) => (
											<Link to={`/stryd/${user.id}/${routine.routineName}`} key={index}>
												<StrydCardSmall
													name={routine.routineName}
													time={routine.estimatedTimeMinutes}
													creator={user.username}
													userID={user.id}
													type={routine.routineCreationType}
												/>
											</Link>
										))
									}
								</div>
							) : (
								<span className={style.EmptyText}>No routines available</span>
							)
						) : (
							<img src={spinner} alt="Loading..." />
						)}
					</div>

					{/* Joined Routines */}
					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Joined Routines</span>
						{dbUser ? (
							dbUser.stryds && dbUser.stryds.joinedRoutines && dbUser.stryds.joinedRoutines.length > 0 ? (
								<div className={style.CardsContainer}>
									{dbUser.stryds.joinedRoutines.map((routine, index) => (
										<Link to={`/stryd/${routine.routineCreatorUserID}/${routine.routineName}`} key={index}>
											<StrydCardSmall
												name={routine.routineName}
												time={routine.estimatedTimeMinutes}
												creator={routine.routineCreator}
											/>
										</Link>
									))}
								</div>
							) : (
								<span className={style.EmptyText}>No routines available</span>
							)
						) : (
							<img src={spinner} alt="Loading..." />
						)}
					</div>
				</div>

			</>

				: <span className={style.EmptyText}>
					user not logged in
				</span>
			}
		</div >
	)
}

export default ProfilePage;