import React from 'react';
import style from './PrimaryButton.module.css';
import spinner from '../../assets/Spinner.svg';

const PrimaryButton = (props) => {
	const buttonStyle = {
		width: props.width || 'auto', // Set a default width or use the provided width
	};

	return (
		<button
			className={style.PrimaryButton}
			onClick={() => props.click()}
			disabled={props.disabled}
			style={buttonStyle} // Set the width using inline CSS styling
		>
			{props.loading ? (
				<img src={spinner} alt="" className={style.spinner} />
			) : (
				<span>{props.text}</span>
			)}
		</button>
	);
};

export default PrimaryButton;
