import React from 'react';
import style from './RanksModal.module.css';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import CupIcon from '../../assets/cupIcon.svg';
import Modal from '../Modal/Modal';
import RoutineMemberCard from '../RoutineMemberCard/RoutineMemberCard';
import Rank from '../Rank/Rank';

const RanksModal = ({ onClose, ranks }) => {
	return (
		<Modal onClose={onClose}>
			<div className={style.RoutineCompleteWrapper}>
				<h3 className={style.RanksHeading}>
					Ranks
				</h3>

				<div className={style.RanksWrapper}>
					{
						ranks.map(rank => <Rank
							name={rank.rank}
							minScore={rank.minScore}
							maxScore={rank.maxScore}
						/>)
					}
				</div>
			</div>
		</Modal>
	);
};


export default RanksModal;