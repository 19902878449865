import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'
import style from './ExploreRoutines.module.css';
import StrydCardSmall from '../../components/StrydCardSmall/StrydCardSmall';
import { Link, useParams } from 'react-router-dom'
import spinner from '../../assets/Spinner.svg'
import FormInput from '../../components/FormInput/FormInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import toast from 'react-simple-toasts';

import {
	useUser,
	clerkClient
} from "@clerk/clerk-react";

const API_URL = process.env.REACT_APP_API_URL;

const ExploreRoutines = () => {
	const { user } = useUser()
	const { userID } = useParams()
	const DEFAULT_USER_ID = "user_2PSVExD2Jze2iGYg75qSShJe8If";


	const [routines, setRoutines] = useState(null);
	const [searchInput, setSearchInput] = useState('')


	const handleSearchTtextChange = event => {
		setSearchInput(event.target.value);
	}

	const getSearchResults = () => {
		if (searchInput === '') {
			toast('Search field is empty')
			return
		}

		setRoutines(null)
		axios.get(`${API_URL}/search/routines/${searchInput}`)
			.then(res => setRoutines(res.data))
			.catch(err => console.log('error', err.message));
		console.log('got results for' + searchInput)
	}


	const getRoutines = useCallback(() => {
		const userId = user?.id || DEFAULT_USER_ID; // Use default user ID if user or user.id is not available

		axios.get(`${API_URL}/recommendations/routines/${userId}`)
			.then(res => setRoutines(res.data))
			.catch(err => console.log('Error fetching routines:', err.message));

		console.log('get routines for userId:', userId);
	}, [API_URL, user]);

	useEffect(() => {
		getRoutines();
	}, [getRoutines]);

	return (
		<div className={style.ExploreRoutines}>
			<div className='SearchContainer'>
				<FormInput
					placeholder='Search for routines. ex: martial arts, home, HIIT, beginner'
					change={handleSearchTtextChange}
					width={window.innerWidth < 500 ? '240px' : '480px'}
					onEnter={() => getSearchResults()}
				/>

				<PrimaryButton
					text='Search'
					width='120px'
					click={() => getSearchResults()}
				/>
			</div>

			{routines ? (
				routines.length > 0 ? ( // Check if routines array has items
					<div className={style.CardsContainer}>
						{routines.map(routine => (
							<Link key={routine.routine.routineName} to={`/stryd/${routine.userID}/${routine.routine.routineName}`}>
								<StrydCardSmall
									name={routine.routine.routineName}
									time={routine.routine.estimatedTimeMinutes}
									creator={routine.username}
									userID={routine.userID}
									key={routine.routine.routineName}
								/>
							</Link>
						))}
					</div>
				) : (
					<p>No routines found.</p>
				)
			) : (
				<img src={spinner} alt="" />
			)}
		</div>
	)
}

export default ExploreRoutines