import React from 'react';
import style from './RoutineCompleteModal.module.css';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import CupIcon from '../../assets/cupIcon.svg';
import Modal from '../Modal/Modal';

const RoutineCompletedModal = ({ onClose, xp, workoutName }) => {
	const shareOnX = () => {
		const text = `I just completed ${workoutName} on Calcium and earned ${xp} XP! Feeling accomplished. Join us.`;
		const url = "https://calcium.fitness"; // Replace with your app's URL
		const tweetUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;

		window.open(tweetUrl, '_blank');
	};


	return (
		<Modal onClose={onClose}>
			<div className={style.RoutineCompleteWrapper}>
				<div className={style.RoutineCompletedHeading}>
					<img src={CupIcon} alt="" className={style.RoutineCupIcon} />
					<span className={style.RoutineTextTitle}>Awesome</span>
				</div>

				<span className={style.RoutineText}>
					You completed your workouts and earned {xp} XP. Great job!
				</span>

				<div className={style.ModalButtons}>
					{/* <PrimaryButton text="Share" /> */}

					<SecondaryButton
						text="Got it"
						click={onClose}
					/>

					<SecondaryButton
						text="Share on X"
						click={shareOnX}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default RoutineCompletedModal;
