import React from 'react';
import { RedirectToSignIn, SignedOut, useUser, SignIn } from "@clerk/clerk-react";
import style from './Login.module.css';

const Login = () => {
	// const { user } = useUser()
	// const navigate = useNavigate();

	return (
		<div className={style.LoginPage}>
			<div className={style.LoginForm}>
				<SignIn redirectUrl={'https://www.calcium.fitness/'} />
			</div>
		</div>
	)
}

export default Login;