import React, { useState } from 'react';
import style from './FormInput.module.css';

const FormInput = props => {
	const [isEmpty, setIsEmpty] = useState(true);

	const inputStyle = {
		width: props.width || 'auto',
		color: isEmpty && props.showIsEmpty ? 'red' : '#BEBEBE',
		borderColor: isEmpty && props.showIsEmpty ? 'red' : '#606060', // Changing border color conditionally
		borderRadius: '5px',
		maxWidth: '640px',
		padding: '5px 5px',
		margin: 'auto',
	};

	const handleInputChange = event => {
		const inputValue = event.target.value;
		setIsEmpty(inputValue === '');
		if (props.change) {
			props.change(event);
		}
	};

	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			if (props.onEnter) {
				props.onEnter(event);
			}
		}
	};

	return (
		<div className={style.FormInputWrapper}>
			<span className={style.QuestionHeading}>{props.HeadingText}</span>
			<input
				type="text"
				className={style.FormTextInput}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				placeholder={props.placeholder}
				style={inputStyle}
			/>
		</div>
	);
};

export default FormInput;
