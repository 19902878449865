import {
	useContext
} from 'react';
import {
	UIContext
} from '../contexts/UIContext';

// Declare a placeholder for the updater function
let globalUpdateUIContext;

const useUpdateUIContext = () => {
	const {
		setGlobalUIState
	} = useContext(UIContext);

	// Define the update function
	const updateUIContext = (screenWidth, currentSection) => {
		setGlobalUIState((prevState) => ({
			...prevState,
			screenWidth,
			currentSection,
		}));
	};

	// Assign the update function to the global variable
	globalUpdateUIContext = updateUIContext;

	return updateUIContext;
};

// Export the global function for external usage
export const updateGlobalUIState = (screenWidth, currentSection) => {
	if (typeof globalUpdateUIContext === 'function') {
		globalUpdateUIContext(screenWidth, currentSection);
	} else {
		console.error('updateGlobalUIState is not initialized. Make sure the UIContext provider is rendered.');
	}
};

export default useUpdateUIContext;