import React from 'react';
import style from './PlanConfirmation.module.css';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import { Link } from 'react-router-dom';


const PlanConfirmation = () => {
	return (
		<div className={style.PlanConfirmation}>
			<span className={style.PlanConfirmationText}>
				Welcome to calcium plus
			</span>

			<div className={style.ButtonWrapperDescription}>
				<span>
					Thank you for subscribing to Calcium plus. 💚
				</span>

				<div className={style.ButtonWrapper}>
					<Link to='/interests'>
						<SecondaryButton
							text='Generate a routine'
							click={() => null}
						/>
					</Link>

					<Link to='/explore-routines'>
						<SecondaryButton
							text='Join a routine'
							click={() => null}
						/>
					</Link>
				</div>

			</div>
		</div >
	)
}

export default PlanConfirmation