import React from 'react';
import style from './Tag.module.css';

const Tag = props => {
	return (
		<div className={style.TagWrapper}>
			{props.label}
		</div>
	)
}

export default Tag;