import React from 'react';
import style from './LeaderBoardsModal.module.css';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import CupIcon from '../../assets/cupIcon.svg';
import Modal from '../Modal/Modal';
import RoutineMemberCard from '../RoutineMemberCard/RoutineMemberCard';

const LeaderBoardsModal = ({ onClose, people }) => {
	return (
		<Modal onClose={onClose}>
			<h3 className={style.LeaderboardsHeading}>
				Leaderboards
			</h3>

			<div className={style.LeaderboardsWrapper}>
				{people.map(person => (
					<RoutineMemberCard
						key={person.id}
						name={person.username}
						image={person.profileImageLink}
						xp={person.userRoutineXP}
					/>
				))}
			</div>
		</Modal>
	);
};

export default LeaderBoardsModal;