import React, { useState } from 'react';
import style from './QuickAccessCard.module.css';

const QuickAccessCard = (props) => {
	const [isHovered, setIsHovered] = useState(false);

	// Event handlers to toggle hover state
	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	// Styles for the card and title
	const cardStyle = {
		backgroundColor: isHovered ? props.hoverColor || props.color : props.color,
		transition: 'background-color 0.3s ease', // Smooth transition for background color
	};

	const textStyle = {
		color: isHovered ? props.hoverTextColor || props.textColor : props.textColor, // Dynamic text color based on hover state
		transition: 'color 0.3s ease', // Smooth transition for text color
	};

	return (
		<div
			className={style.QuickAccessCard}
			style={cardStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<div className={style.QuickAccessCardWrapper}>
				<img
					className={style.QuickAccessCardIcon}
					src={props.icon}
					alt="Card Icon"
					
				/>
				<span className={style.QuickAccessCardText} style={textStyle}>
					{props.title}
				</span>
			</div>
		</div>
	);
};

export default QuickAccessCard;