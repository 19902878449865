import React, { useState, useCallback, useEffect } from 'react';
import toast, { toastConfig } from 'react-simple-toasts';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import style from '../ProfilePage/ProfilePage.module.css'
import StrydCardSmall from '../../components/StrydCardSmall/StrydCardSmall';
import spinner from '../../assets/Spinner.svg'
import Checkbox from '../../components/CheckBox/CheckBox';
import Tag from '../../components/Tag/Tag'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import RanksModal from '../../components/RanksModal/RanksModal';


import rankData from '../../utils/ranks';
import findUserRank from '../../utils/findUserRank';

import {
	useUser,
} from "@clerk/clerk-react";
import profileIcon from '../../assets/profileIconTwo.svg'
import { Resend } from 'resend';

const resend = new Resend('re_ZjFXn93w_7SgehP85hLhgCaHQqDVaiTa1');

const UserProfile = () => {
	const { userID } = useParams();
	const { user } = useUser()
	const navigate = useNavigate()
	const API_URL = process.env.REACT_APP_API_URL;

	const [person, setPerson] = useState(null);
	const [following, setFollowing] = useState(false)
	const [followerNumber, setFollowerNumber] = useState(0)
	const [isRanksModalOpen, setIsRanksModalOpen] = useState(false)


	const getUser = useCallback(() => {
		axios.get(`${API_URL}/users/${userID}`)
			.then(res => (setPerson(res.data[0])))
			.catch(err => console.log("Error:", err.message));
	}, [API_URL, user]);

	const handleOpenRanksModal = () => {
		setIsRanksModalOpen(true)
	}

	const handleCloseRanksModal = () => {
		setIsRanksModalOpen(false)
	}

	toastConfig({ theme: 'dark' });

	const isUserFollowing = (currentUser, person) => {
		if (currentUser && person && person.socials && person.socials.followers) {
			const followerIDs = person.socials.followers.map(follower => follower.userID);
			const isFollowing = followerIDs.includes(currentUser);
			return isFollowing;
		}
		return false; // Default to false if data is missing
	};


	const followUser = async (userID, followerData, emailData) => {
		setFollowing(true);
		setFollowerNumber(followerNumber + 1);
		toast('Following user');

		try {
			// Sending follow request
			const followResponse = await axios.post(`${API_URL}/users/${userID}/follow`, followerData);
			console.log(followResponse.data);
			toast('Followed user');

			// Sending email notification
			const emailResponse = await axios.post(`${API_URL}/emails/send-email`, emailData);
			console.log(emailResponse.data);
			toast('Email notification sent');

			// Handle successful responses
		} catch (error) {
			console.error(error.response?.data || error.message); // Handle error response
			toast('Error occurred');
		}
	};


	const unfollowUser = async (userID, unfollowerData) => {
		setFollowing(false)
		setFollowerNumber(followerNumber - 1)
		toast('Unfollowing user')
		try {
			const response = await axios.post(`${API_URL}/users/${userID}/unfollow`, unfollowerData)
			console.log(response.data)
			toast('unfollowed user')
		} catch (error) {
			console.error(error.response.data)
		}
	}


	useEffect(() => {
		if (!person) {
			getUser();
		}

		if (user && person && person.socials) {
			if (user.id === userID) {
				navigate('/profile-page')
			}

			const isFollowing = isUserFollowing(user.id, person);
			setFollowing(isFollowing);
			setFollowerNumber(person.socials.followers.length);
		}
	}, [person, getUser, user]);



	return (
		<div className={style.ProfilePage}>
			{person ? <>

				{
					isRanksModalOpen && (
						<RanksModal
							onClose={handleCloseRanksModal}
							ranks={rankData}
						/>
					)
				}


				<div className={style.ProfileInfoWrapper}>
					<div className={style.ProfileUserStats}>
						<div>
							<div className={style.ProfileInfo}>
								<img
									className={style.ProfilePicture}
									src={person.profileImageLink}
									alt="" />

								<div className={style.ProfileInfoDetails}>
									<div className={style.ProfileInfoText}>
										<span className={style.ProfileInfoUsername}>
											{person.username}
										</span>

										<span className={style.ProfileRank}
											onClick={() => {
												handleOpenRanksModal()
											}}>
											{findUserRank(person.totalXP, rankData)}
										</span>
									</div>

									<div className={style.ProfileAttributesWrapper}>
										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Followers
											</span>

											<span className={style.ProfileCount}>
												{followerNumber}
											</span>
										</div>

										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Folowing
											</span>

											<span className={style.ProfileCount}>
												{person.socials.followings.length || 0}
											</span>
										</div>

										<div className={style.ProfileAttribute}>
											<span className={style.ProfileAttributeHeading}>
												Total XP
											</span>

											<span className={style.ProfileCount}>
												{person.totalXP || 0}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>


						{user && (
							following ? (
								<PrimaryButton
									text="Following"
									click={() => unfollowUser(
										userID, {
										username: user.username,
										profileImageLink: user.profileImageUrl,
										userID: user.id
									}
									)}
								/>
							) : (
								<PrimaryButton
									text="Follow"
									click={() => followUser(
										userID,
										{
											username: user.username,
											profileImageLink: user.profileImageUrl,
											userID: user.id
										},
										{
											to: person.email,       // Recipient's email address
											from: 'hello@calcium.fitness',    // Your (or sender's) email address
											subject: 'You Have a New Follower.', // Subject line of the email
											html:
												`<h4>Hi, ${person.username}.</h4>
											<p>${user.username} just followed you on Calcium.</p>
											<p>Cheers!</p>
											<p>Your friends at Calcium.</p>
											`// HTML content of the email
										}
									)}
								/>
							)
						)}

						<div>
							<div>
								<span>
									{
										person ? (
											<div className={style.UserInterests}>
												{person.interests && person.interests.workoutInterests && person.interests.workoutInterests.length > 0 ? (
													person.interests.workoutInterests.map((interest) => (
														<Tag label={interest} key={interest} />
													))
												) : (
													<span className={style.EmptyText}>
														No workout interests
													</span>
												)}
											</div>
										) : (
											<span>Loading</span>
										)
									}

								</span>
							</div>
						</div>
					</div>
				</div>

				<div className={style.UserRoutines}>
					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Generated Routines</span>
						{person ? (
							<div className={style.CardsContainer}>
								{Array.isArray(person.stryds?.routines) && person.stryds.routines.length > 0 ? (
									person.stryds.routines
										.filter((routine) => routine.routineCreationType === 'generated')
										.map((routine, index) => (
											<Link to={`/stryd/${userID}/${routine.routineName}`} key={index}>
												<StrydCardSmall
													name={routine.routineName}
													time={routine.estimatedTimeMinutes}
													creator={person.username}
												/>
											</Link>
										))
								) : (
									<span className={style.EmptyText}>No routines</span>
								)}
							</div>
						) : (
							<span>Loading</span>
						)}
					</div>

					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Added Routines</span>
						{person ? (
							<div className={style.CardsContainer}>
								{Array.isArray(person.createdStryds?.routines) && person.createdStryds.routines.length > 0 ? (
									person.createdStryds.routines.map((routine, index) => (
										<Link to={`/stryd/${userID}/${routine.routineName}`} key={index}>
											<StrydCardSmall
												name={routine.routineName}
												time={routine.duration}
											/>
										</Link>
									))
								) : (
									<span className={style.EmptyText}>No Added routines</span>
								)}
							</div>
						) : (
							<span>Loading</span>
						)}
					</div>

					<div className={style.CardsWrapper}>
						<span className='SectionTitle'>Created Routines</span>
						{person ? (
							<div className={style.CardsContainer}>
								{Array.isArray(person.stryds?.routines) && person.stryds.routines.length > 0 ? (
									person.stryds.routines
										.filter((routine) => routine.routineCreationType === 'created')
										.map((routine, index) => (
											<Link to={`/stryd/${userID}/${routine.routineName}`} key={index}>
												<StrydCardSmall
													name={routine.routineName}
													time={routine.duration}
													creator={person.username}
												/>
											</Link>
										))
								) : (
									<span className={style.EmptyText}>No created routines</span>
								)}
							</div>
						) : (
							<span>Loading</span>
						)}
					</div>
				</div>

			</>
				:
				<img src={spinner} alt="" />
			}
		</div >
	)
}

export default UserProfile;