import React, { useState } from "react";
import style from "./CheckBox.module.css";

function Checkbox(props) {
	const [isChecked, setIsChecked] = useState(false);

	function handleCheckboxClick() {
		const newValue = !isChecked;
		setIsChecked(newValue);
		props.onChange && props.onChange(newValue);
		props.onCheckboxChange && props.onCheckboxChange(newValue);
	}


	return (
		<div className={`${style.checkbox} ${isChecked ? style.checked : ""}`} onClick={handleCheckboxClick}>
			<div className={style.checkboxBox}>
				<span className={style.checkboxLabel}>{props.label}</span>
			</div>
		</div>
	);
}

export default Checkbox;
