import React, {useContext} from 'react';
import style from './BottomNav.module.css';
import { Link } from 'react-router-dom';

import homeIcon from '../../assets/homeIcon.svg'
import routinesIcon from '../../assets/routinesIcon.svg'
import profileIcon from '../../assets/profileIcon.svg'
import peopleIcon from '../../assets/peopleIcon.svg'
import navPlusIcon from '../../assets/navPlusIcon.svg'

import { UIContext } from '../../contexts/UIContext';



const BottomNav = () => {
	const {  globalUIState, setGlobalUIState } = useContext(UIContext);

	return (
		<div className={style.BottomNav}>
			<div className={style.BottomNavWrapper}>
				<Link to='/'>
					<button className={style.IconButton}>
						<img
							src={homeIcon}
							alt=""
							className={style.Icon}
						/>
						<span className={style.ButtonText}>Home</span>
					</button>
				</Link>

				<Link to='/explore-routines'>
					<button className={style.IconButton}>
						<img
							src={routinesIcon}
							alt=""
							className={style.Icon}
						/>
						<span className={style.ButtonText}>Routines</span>
					</button>
				</Link>

				<Link to='/choice'>
					<button className={style.IconButton}>
						<img
							src={navPlusIcon}
							alt=""
							className={style.Icon}
						/>
						<span className={style.ButtonText}>Add</span>
					</button>
				</Link>

				<Link to='/people'>
					<button className={style.IconButton}>
						<img
							src={peopleIcon}
							alt=""
							className={style.Icon}
						/>
						<span className={style.ButtonText}>People</span>
					</button>
				</Link>

				<Link to='/profile-page'>
					<button className={style.IconButton}>
						<img
							src={profileIcon}
							alt=""
							className={style.Icon}
						/>
						<span className={style.ButtonText}>Profile</span>
					</button>
				</Link>
			</div>
		</div>
	)
}

export default BottomNav;