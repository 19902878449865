import React, { useState, useEffect } from 'react';
import style from './Timer.module.css';
import stopwatchIcon from '../../assets/stopwatchIcon.svg';
import playIcon from '../../assets/playIcon.svg'
import resetIcon from '../../assets/resetIcon.svg';
import pauseIcon from '../../assets/pauseIcon.svg';

const Timer = () => {
	const [seconds, setSeconds] = useState(0);
	const [milliseconds, setMilliseconds] = useState(0);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		let interval = null;

		if (isActive) {
			interval = setInterval(() => {
				setMilliseconds((prevMilliseconds) => {
					const newMilliseconds = prevMilliseconds + 10;
					if (newMilliseconds === 1000) {
						setSeconds((prevSeconds) => prevSeconds + 1);
						return 0;
					}
					return newMilliseconds;
				});
			}, 10);
		} else if (!isActive && (seconds !== 0 || milliseconds !== 0)) {
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [isActive, seconds, milliseconds]);

	const toggleTimer = () => {
		setIsActive(!isActive);
	};

	const resetTimer = () => {
		setIsActive(false);
		setSeconds(0);
		setMilliseconds(0);
	};

	return (
		<div className={style.RoutineTimerContainer}>
			<div className={style.RoutineTimerWrapper}>
				<div className={style.RoutineTimer}>
					<img
						className={style.RoutineTimerIcon}
						src={stopwatchIcon}
						alt=""
					/>
					<span className={style.RoutineTimerText}>
						{Math.floor(seconds / 60)}:{(seconds % 60).toLocaleString('en-US', {
							minimumIntegerDigits: 2,
							useGrouping: false,
						})}
						.{(milliseconds / 10).toLocaleString('en-US', {
							minimumIntegerDigits: 2,
							useGrouping: false,
						})}
						s
					</span>
				</div>
				<div className={style.RoutineTimerActions}>
					<img
						src={isActive ? pauseIcon : playIcon}
						alt=""
						className={isActive ? style.RoutinePauseIcon : style.RoutinePlayIcon}
						onClick={toggleTimer}
					/>
					<img
						src={resetIcon}
						alt=""
						className={style.RoutineResetIcon}
						onClick={resetTimer}
					/>
				</div>
			</div>
		</div>
	);
};

export default Timer;
