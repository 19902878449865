import React, { useState, useCallback, useContext, useEffect } from 'react';
import style from './UpgradeCard.module.css';
import axios from 'axios';
import { UserContext } from '../../contexts/UserContext';

import {
	useUser,
} from "@clerk/clerk-react";
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { useNavigate } from 'react-router-dom';

import { PaystackButton } from 'react-paystack';

const UpgradeCard = (props) => {
	console.log(props.planCheckoutUrl)
	const { user } = useUser();
	const [lemonSqueezyResponse, setLemonSqueezyResponse] = useState({});
	const navigate = useNavigate()
	const { dbUser, setDbUser } = useContext(UserContext);

	const PAYSTACK_KEY = process.env.REACT_APP_PAYSTACK_KEY;
	const apiKey = process.env.REACT_APP_LEMONSQUEEZY_API_KEY;
	const API_URL = process.env.REACT_APP_API_URL;

	const config = {
		reference: (new Date()).getTime().toString(),
		email: dbUser.email,
		amount: props.amount,
		publicKey: PAYSTACK_KEY,
	};

	const getCheckoutInfo = async () => {
		try {
			const response = await axios.post('https://api.lemonsqueezy.com/v1/checkouts', props.lemonSqueezyData, {
				headers: {
					'Accept': 'application/vnd.api+json',
					'Content-Type': 'application/vnd.api+json',
					'Authorization': `Bearer ${apiKey}`
				}
			});
			setLemonSqueezyResponse(response)
			return response.data.links.self;
		} catch (error) {
			console.error('Error creating checkout:', error);
			throw error;
		}
	};


	useEffect(() => {
		const initializeCheckout = async () => {
			try {
				if (window.createLemonSqueezy) {
					console.log('upgrfadeppsdpsld', window.LemonSqueezy);
					console.log('lemon.js is loaded');
				} else {
					console.warn('lemon.js is not loaded');
					return;
				}

				const response = await getCheckoutInfo();
				console.log('Checkout response:', response.data);

				if (lemonSqueezyResponse) {
					var checkoutUrl = lemonSqueezyResponse.data.links.self;
					console.log(checkoutUrl)
					// console.log('jdjdjdjjdjdjdj', LemonSqueezy)
				}

				console.log(checkoutUrl)
			} catch (error) {
				console.error('Failed to initialize checkout:', error);
			}
		};

		initializeCheckout();
	}, []);


	return (
		<div className={style.UpgradeCardWrapper}>
			<div className={style.UpgradeCardDetails}>
				<span className={style.UpgradeCardName}>
					{props.planName}
				</span>

				<span className={style.UpgradeCardAmount}>
					{props.amountText}
				</span>

				<span>{props.text}</span>
			</div>

			<div className={style.UpgradeCardFeaturesContainer}>
				<span className={style.UpgradeCardFeature}>
					Get full access to Calcium.
				</span>

				<span className={style.UpgradeCardFeature}>
					Earn XP.
				</span>

				<span className={style.UpgradeCardFeature}>
					Increase ranks as you workout.
				</span>
			</div>

			{/* {
				dbUser.userPlan === props.planType ? (
					<SecondaryButton
						text='Subscribed'
						click={() => null}
					/>) : (
					<PaystackButton {...componentProps} />
				)} */}

			{
				dbUser.userPlan === props.planType ? (
					<SecondaryButton
						text='Subscribed'
						click={() => null}
					/>) :
					lemonSqueezyResponse.data ?
						<button
							onClick={() => {
								window.LemonSqueezy.Url.Open(lemonSqueezyResponse.data.data.attributes.url)
								// window.LemonSqueezy.Setup()
								// console.log('ddddd' + lemonSqueezyResponse.data.data.attributes.url)
							}
							}
						>
							{props.planName}
						</button>
						: <span>loading</span>
			}
		</div>
	);

}


export default UpgradeCard;