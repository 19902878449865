import React, { useState } from 'react';
import axios from 'axios';

import style from './AddRoutinePage.module.css';

import Checkbox from '../../components/CheckBox/CheckBox';
import RadioButton from '../../components/RadioButton/RadioButton';
import FormInput from '../../components/FormInput/FormInput';
import AddChallengeCard from '../../components/AddChallengeCard/AddChallengeCard';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import ChallengeCard from '../../components/ChallengeCard/ChallengeCard';
import AddBreakCard from '../../components/AddBreakCard/AddBreakCard';
import toast, { toastConfig } from 'react-simple-toasts';
import { useNavigate } from 'react-router-dom';
import formatDate from '../../utils/formatDate';



import { useUser } from "@clerk/clerk-react";
const API_URL = process.env.REACT_APP_API_URL;

const AddRoutinePage = () => {
	const { user } = useUser();
	const navigate = useNavigate()

	const fitnessLevelArray = ['Beginner', 'Intermediate', 'Advanced'];
	const durationArray = ['30', '45', '60'];
	const restDaysArray = ['1', '2', '3'];

	const [showBreakCard, setShowBreakCard] = useState(false)

	const [routinesArray, setRoutineArray] = useState([]);
	const [routineAttributes, setRoutineAttributes] = useState({
		level: '',
		estimatedTimeMinutes: '',
		restDays: '',
		routineName: '',
		routineDescription: '',
		routineEquipments: '',
		workouts: [],
		createdDate: formatDate(), // Add current date in the specified format
		routineCreationType: 'created', // Add routineCreationType

	});

	const isFormValid = () => {
		const { workouts, level, estimatedTimeMinutes, routineName } = routineAttributes;
		return workouts.length > 0 && level !== '' && estimatedTimeMinutes !== '' && routineName !== '';
	};


	const addStryds = async () => {
		const valid = isFormValid();

		if (!valid) {
			toast('Some fields are empty, please check the fields marked as required.');
			return; // Prevent further execution if the form is invalid
		}

		const userToAdd = {
			userID: user.id,
			username: user.username,
			profileImageLink: user.profileImageUrl,
			userRoutineXP: 0
			// Add other data needed for joining the routine
		};

		try {
			toast('Adding routine');
			const res = await axios.post(`${API_URL}/stryds/${user.id}`, routineAttributes);
			// setIsLoading(true);
			console.log(res);


			// Add user as participant after the first request succeeds
			const response = await axios.post(
				`${API_URL}/stryds/join-routine/${user.id}/${routineAttributes.routineName}`,
				userToAdd
			);

			toast('Added routine');
			console.log(response.data); // Handle successful response
			// toast('joined routine')
			// setIsLoading(false);
			navigate('/profile-page');
			return response.data;
		} catch (error) {
			console.error(error.response.data); // Handle error response
			// setIsLoading(false);
			throw new Error(error.response.data);
		}
	};


	const handleAddRoutine = newChallengeData => {
		setRoutineArray(prevRoutinesArray => {
			const updatedRoutinesArray = [...prevRoutinesArray, newChallengeData];
			setRoutineAttributes(prevRoutineAttributes => ({
				...prevRoutineAttributes,
				workouts: updatedRoutinesArray
			}));
			return updatedRoutinesArray;
		});
	};


	const handleRoutineDescriptionChange = event => {
		setRoutineAttributes(prevState => ({ ...prevState, routineDescription: event.target.value }))
	}

	const handleRoutineEquipmentsChange = event => {
		setRoutineAttributes(prevState => ({ ...prevState, routineEquipments: event.target.value }))
	}

	const handleRoutineNameChange = event => {
		setRoutineAttributes(prevState => ({ ...prevState, routineName: event.target.value }))
	}

	const handleLevelChange = (level) => () => {
		setRoutineAttributes(prevState => ({ ...prevState, level }));
	};

	const handleDurationChange = (estimatedTimeMinutes) => () => {
		setRoutineAttributes(prevState => ({ ...prevState, estimatedTimeMinutes }));
	};

	const handleRestDaysChange = (restDays) => () => {
		setRoutineAttributes(prevState => ({ ...prevState, restDays }))
	}

	const deleteRoutine = index => {
		setRoutineArray(prevRoutinesArray => {
			const updatedRoutinesArray = [...prevRoutinesArray];
			updatedRoutinesArray.splice(index, 1);
			setRoutineAttributes(prevRoutineAttributes => ({
				...prevRoutineAttributes,
				workouts: updatedRoutinesArray
			}));
			return updatedRoutinesArray;
		});
	};


	return (
		<div className={style.AddRoutinePage}>
		  {user ? (
			<>
			  <section className={style.AttributeSection}>
				<span className={style.AttributeSectionHeading}>Add Routine Details</span>
				<FormInput
				  HeadingText="Routine name - required"
				  change={handleRoutineNameChange}
				  width={window.innerWidth < 500 ? "300px" : "480px"}
				  showIsEmpty={true}
				/>
	  
				<FormInput
				  HeadingText="Routine description"
				  change={handleRoutineDescriptionChange}
				  width={window.innerWidth < 500 ? "300px" : "480px"}
				  showIsEmpty={true}
				/>
	  
				<FormInput
				  HeadingText="Routine equipmemts - required"
				  change={handleRoutineEquipmentsChange}
				  width={window.innerWidth < 500 ? "300px" : "480px"}
				  showIsEmpty={true}
				/>
			  </section>
	  
			  <section className={style.AttributeSection}>
				<span className={style.AttributeSectionHeading}>Create Routine</span>
	  
				<div className={style.AttributeSelectionWrapper}>
				  <span className={style.QuestionHeading}>Level - required</span>
				  <div className={style.AttributeSelection}>
					{fitnessLevelArray.map((level, index) => (
					  <RadioButton
						key={level}
						label={level}
						value={level}
						name="levels"
						isChecked={routineAttributes.level === level}
						onRadioChange={handleLevelChange(level)}
					  />
					))}
				  </div>
				</div>
	  
				<div className={style.AttributeSelectionWrapper}>
				  <span className={style.QuestionHeading}>Duration - required</span>
				  <div className={style.AttributeSelection}>
					{durationArray.map((estimatedTimeMinutes, index) => (
					  <RadioButton
						key={estimatedTimeMinutes}
						label={estimatedTimeMinutes + " mins"}
						value={estimatedTimeMinutes}
						name="durations"
						isChecked={routineAttributes.estimatedTimeMinutes === estimatedTimeMinutes}
						onRadioChange={handleDurationChange(estimatedTimeMinutes)}
					  />
					))}
				  </div>
				</div>
	  
				{/* 
				<div className={style.AttributeSelectionWrapper}>
				  <span className={style.QuestionHeading}>Amount of Rest days</span>
				  <div className={style.AttributeSelection}>
					{restDaysArray.map((restDay, index) => (
					  <RadioButton
						key={restDay}
						label={`${restDay} day(s)`}
						value={restDay}
						name="durations"
						isChecked={routineAttributes.restDays === restDay}
						onRadioChange={handleRestDaysChange(restDay)}
					  />
					))}
				  </div>
				</div> 
				*/}
			  </section>
	  
			  <section className={style.AttributeSection}>
				<span className={style.AttributeSectionHeading}>Add workouts - required</span>
				<div className={style.CardsWrapper}>
				  {routinesArray.map((routine, index) => (
					<ChallengeCard
					  key={index}
					  workoutName={routine.workoutType}
					  workoutSets={routine.workoutSets}
					  workoutReps={routine.workoutReps}
					  deleteable={true}
					  delete={() => deleteRoutine(index)}
					/>
				  ))}
	  
				  <AddChallengeCard addRoutine={handleAddRoutine} />
	  
				  {/* 
				  {showBreakCard ? (
					<AddBreakCard addRoutine={handleAddRoutine} />
				  ) : null}
				  */}
	  
				  {/* 
				  <div className={style.AddChallengeCardOptions}>
					<span className={style.OptionsButton}>
					  Add custom workout
					</span>
				  </div> 
				  */}
				</div>
			  </section>
	  
			  <div>
				<PrimaryButton text="Save routine" click={addStryds} 
				// disabled={!isFormValid()} 
				/>
			  </div>
			</>
		  ) : (
			<span className={style.EmptyText}>user not logged in</span>
		  )}
		</div>
	  );
	}
	  

export default AddRoutinePage;