import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { dark } from '@clerk/themes';
import 'react-simple-toasts/dist/theme/dark.css'; // choose your theme


import {
	ClerkProvider,
} from "@clerk/clerk-react";


if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<ClerkProvider
		appearance={{
			baseTheme: dark,
			variables: { colorPrimary: '#393D1B' }
		}}
		publishableKey={clerkPubKey}>

		<React.StrictMode>
			<App />
		</React.StrictMode>
	</ClerkProvider>
);