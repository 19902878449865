import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from "@clerk/clerk-react";
import spinner from '../../assets/Spinner.svg'
import timeIcon from '../../assets/timeIcon.svg'
import formatDate from '../../utils/formatDate';

import style from './GeneratedStryd.module.css';
import StrydCardSmall from '../../components/StrydCardSmall/StrydCardSmall';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';

import { generateWorkoutRoutines } from '../../utils/generateRoutines';


const API_URL = process.env.REACT_APP_API_URL;

const GeneratedStryds = props => {
	const { user } = useUser();
	const navigate = useNavigate()

	const [interests, setInterests] = useState({})
	const [routine, setRoutine] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [progress, setProgress] = useState(0);
	const [idk, setIdk] = useState(false)


	// Claude Prompt
	const prompt = `
	You are tasked with generating a personalized workout routine based on user information. The input will be provided in the following format:
	
	<workout_info>
		- type: [${interests.type}]
		- Interests: [${interests.workoutInterests}]
		- equipments: [${interests.workoutEquipments}]
		- Fitness Level: ${interests.level}
		- Workout Style: ${interests.style}
		- Workout Duration: ${interests.duration}
		- Age Range: ${interests.ageRange}
		- Gender: ${interests.gender}
		- Workout Goal: ${interests.goal}
		- Workout adittional info: ${interests.additionalText}
	</workout_info>

	First, carefully parse the input to extract all relevant information about the user's preferences, fitness level, and goals.

Using this information, create a highly personalized and efficient workout routine. Consider the following guidelines:

1. Tailor the routine to the user's fitness level, interests, and available equipment.
2. Ensure the workout style and duration match the user's preferences.
3. Take into account the user's age range and gender when designing the routine.
4. Focus on exercises that will help achieve the user's specific workout goal.
5. Incorporate any additional information provided to further customize the routine.
6. Favour indivdual excercises/workouts over general ones

Generate a workout routine that is accurate, simple and easy, efficient, and perfectly tailored to the user's needs. Aim for excellence in personalization.

Present your response as a single JSON object with the following structure:

{
  "routineName": "3 words maximum",
  "estimatedTimeMinutes": number,
  "routineReason": "2 sentences explaining why this routine was designed for the user",
  "workouts": [
    {
      "workoutType": "string",
      "workoutSets": "string",
      "workoutReps": "string"
    },
    ...
  ]
}

Include exactly 5 workout items in the "workouts" array. Ensure the JSON is valid and can be parsed directly.

Before finalizing your response, review the routine to ensure it meets the following criteria:
1. It accurately reflects all the user's preferences and constraints.
2. The exercises are appropriate for the user's fitness level and goals.
3. The routine makes optimal use of the available equipment.
4. The estimated time matches the user's preferred workout duration.
5. The routine reason clearly explains how this workout is personalized for the user.

Once you have created and reviewed the workout routine, present your final response containing only the JSON object without any additional text.`;


	const getInterests = useCallback(async () => {
		try {
			const res = await axios.get(`${API_URL}/interests/${user.id}`);
			setInterests({ ...res.data });
			console.log("got interests");
			setIdk(Object.keys(interests).length !== 0)
		} catch (err) {
			console.log(err);
		}
	}, [user]);

	const updatedRoutine = {
		...routine,
		createdDate: formatDate(), // Add current date in the specified format
		routineCreationType: 'generated', // Add routineCreationType
	};


	const addStryds = async () => {
		const userToAdd = {
			userID: user.id,
			username: user.username,
			profileImageLink: user.profileImageUrl,
			userRoutineXP: 0
			// Add other data needed for joining the routine
		};

		try {
			const res = await axios.post(`${API_URL}/stryds/${user.id}`, updatedRoutine);
			setIsLoading(true);
			console.log(res);

			// Add user as participant after the first request succeeds
			const response = await axios.post(
				`${API_URL}/stryds/join-routine/${user.id}/${routine.routineName}`,
				userToAdd
			);

			console.log(response.data); // Handle successful response
			// toast('joined routine')
			setIsLoading(false);
			navigate('/profile-page');
			return response.data;
		} catch (error) {
			console.error(error.response.data); // Handle error response
			setIsLoading(false);
			throw new Error(error.response.data);
		}
	};




	useEffect(() => {
		if (user) {
			getInterests();
		}
	}, [user]);
	

	useEffect(() => {
		if (Object.keys(interests).length !== 0) {
			console.log('generating routines')
			generateWorkoutRoutines(prompt)
				.then(
					(routine) => {
						console.log('Generated Workout Routine:');
						console.log(routine)
						console.log(JSON.parse(routine));
						setRoutine(JSON.parse(routine))
						setIsLoading(false)
					}
				)
				.catch(err => console.error('Failed to generate workout routine:', err))
		}
	}, [interests, prompt])


	return (
		<>
			{routine && Object.keys(routine).length > 0 ? (
				<div className={style.GeneratedStrydsWrapper}>
					<div className={style.GeneratedStryds}>
						<div className={style.PageHeadingWrapper}>
							<span className={style.PageTitle}>
								We made this just for you! 💚
							</span>
						</div>

						<div className={style.RoutineWrapper}>
							<div className={style.RoutineDetailsWrapper}>
								<div className={style.RoutineNameWrapper}>
									<span className={style.RoutineName}>
										{routine.routineName}
									</span>
								</div>

								<div className={style.RoutineTimeWrapper}>
									<img src={timeIcon} alt="" className={style.TimeIcon} />
									<span className={style.RoutineText}>{routine.estimatedTimeMinutes} mins</span>
								</div>
							</div>

							<div className="line" />

							<span className={style.RoutineExplanation}>
								{routine.routineReason}
							</span>

							<div className="line" />

							<div className={style.RoutineWorkoutsWrapper}>
								{routine.workouts.map((workout, index) => (
									<div className={style.RoutineWorkouts} key={index}>
										<span>{workout.workoutType}</span>
										<div className={style.RoutineWorkoutsNumber}>
											<span>{`${workout.workoutSets} sets`}</span>
											<span>{`${workout.workoutReps} reps`}</span>
											{/* <span>{routine.createdDate} {routine.routineCreationType}</span> */}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className={style.ButtonsWrapper}>
						<PrimaryButton
							text='Proceed'
							click={addStryds}
						// loading={isLoading}
						/>

						<SecondaryButton
							text='Regenerate'
							click={() => {
								window.location.reload(); // This will reload the page
							}}
						/>
					</div>
				</div>
			) : (
				<div className={style.RoutineGenerationText}>
					<img
						src={spinner}
						alt=""
						className={style.RoutineGenerationSpinner}
					/>
					<span color='#FFF'>Generating your routine</span>
				</div>
			)}
		</>
	);

}



export default GeneratedStryds;