import React from 'react';
import style from './RadioButton.module.css';

function RadioButton(props) {
	const { label, value, isChecked, onRadioChange, name } = props;

	function handleRadioClick() {
		onRadioChange && onRadioChange(value);
		console.log('clicked')
	}

	return (
		<div className={`${style.radio} ${isChecked ? style.checked : ''}`} onClick={handleRadioClick}>
			<div className={style.radioBox}>
				<div className={style.radioInner} />
				<span className={style.radioLabel}>{label}</span>
			</div>
			<input type="radio" name={name} value={value} checked={isChecked} style={{ display: "none" }} />
		</div>
	);
}

export default RadioButton;
