import React from 'react';
import style from './SecondaryButton.module.css'
import spinner from '../../assets/Spinner.svg';


const SecondaryButton = props => {
	const buttonStyle = {
		width: props.width || 'auto', // Set a default width or use the provided width
	};

	return (
		<button
			className={style.SecondaryButton}
			onClick={() => props.click()}
			disabled={props.disabled}
			style={buttonStyle} // Set the width using inline CSS styling

		>
			{props.loading ? (
				<img src={spinner} alt="" className={style.spinner} />
			) : (
				<span>{props.text}</span>
			)}		</button>
	)
}

export default SecondaryButton;