import React, { useState, useCallback, useContext } from 'react';
import { checkSubscription } from '../../utils/checkSubscription';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import logo from '../../assets/calcium-logo.svg'
import { UserContext } from '../../contexts/UserContext';
import { UIContext } from '../../contexts/UIContext';

import {
	UserButton,
	useUser,
} from "@clerk/clerk-react";
import style from './NavBar.module.css';

const NavBar = () => {
	const { user } = useUser()
	const { dbUser, setDbUser } = useContext(UserContext);
	const { globalUIState, setGlobalUIState } = useContext(UIContext);
	const API_URL = process.env.REACT_APP_API_URL;

	// const getUser = useCallback(() => {
	// 	if (user) {
	// 		axios.get(`${API_URL}/users/${user.id}`)
	// 			.then(res => (setDbUser(res.data[0])))
	// 			.catch(err => console.log("Error:", err.message));
	// 	}
	// }, [API_URL, user]);


	return (
		<>
			<nav className={style.NavBar}>
				<Link to='/'>
					<div className={style.NavLogoContainer}>
						<div className={style.NavLogoWrapper}>
							<div className={style.NavLogo}>
								{/* Conditional rendering based on user plan using the checkSubscription function */}
								{checkSubscription(dbUser) ? (
									<div className={style.UserNavWithUpgrade}>
										<div>
											<img src={logo} alt="" className={style.NavBarLogo} />
											<span className={style.UserNavInfo}>Calcium Plus</span>
										</div>
									</div>
								) : (
									<div className={style.UserNavWithUpgrade}>
										<div>
											<img src={logo} alt="" className={style.NavBarLogo} />
											<span className={style.UserNavInfo}>Calcium</span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</Link>


				{
					user ?
						<div className={style.UserNavInfo}>
							<div>
								{/* Show upgrade button only for non-subscribed users */}
								{!checkSubscription(dbUser) ? (
									<Link to='upgrade'>
										<SecondaryButton
											text='Upgrade'
											click={() => null}
										/>
									</Link>
								) : (
									<Link to='upgrade'>
										<SecondaryButton
											text='Plans'
											click={() => null}
										/>
									</Link>
								)}
							</div>

							<span className={style.Username}>
								Hello, {user.username}
							</span>
							<UserButton />
						</div>
						:
						<Link to='/login'>
							<PrimaryButton text='Log in' click={() => null} />
						</Link>

				}
			</nav>
		</>
	)
}

export default NavBar;