import React from 'react';
import style from './RoutineMemberCard.module.css';
import testdp from '../../assets/testdp.png';

const RoutineMemberCard = props => {
	return (
		<div className={style.RoutineMemberCard}>
			<img
				src={props.image}
				alt=""
				className={style.RoutineMemberImage}
			/>

			<span className={style.RoutineMemberName}>
				{props.name}
			</span>

			<span className={style.RoutineMemberScore}>
				{props.xp} XP
			</span>
		</div>
	)
}

export default RoutineMemberCard;