import React, { useEffect } from 'react';
import styles from './Modal.module.css';

function getScrollbarWidth() {
	// Create an invisible container
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll'; // Forcing scrollbar to appear
	outer.style.msOverflowStyle = 'scrollbar'; // Needed for WinJS apps
	document.body.appendChild(outer);

	// Create an inner div without any scrollbars
	const inner = document.createElement('div');
	outer.appendChild(inner);

	// Calculate the scrollbar width
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

	// Remove the divs
	document.body.removeChild(outer);

	return scrollbarWidth;
}

const Modal = ({ children, onClose }) => {
	const handleOverlayClick = (e) => {
		if (e.target === e.currentTarget) {
			onClose();
		}
	};

	useEffect(() => {
		// Get the scrollbar width
		const scrollbarWidth = getScrollbarWidth();
		const originalStyle = window.getComputedStyle(document.body).overflow;
		const originalPaddingRight = window.getComputedStyle(document.body).paddingRight;

		// Disable scrolling and add padding to compensate for the missing scrollbar
		document.body.style.overflow = 'hidden';
		document.body.style.paddingRight = `${parseFloat(originalPaddingRight) + scrollbarWidth}px`;

		return () => {
			// Reset the styles
			document.body.style.overflow = originalStyle;
			document.body.style.paddingRight = originalPaddingRight;
		};
	}, []);

	return (
		<div className={styles.ModalOverlay} onClick={handleOverlayClick}>
			<div className={styles.ModalContent}>
				{children}
			</div>
		</div>
	);
};

export default Modal;