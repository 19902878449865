import React from 'react';
import { Link } from 'react-router-dom';

import style from './WelcomePage.module.css';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import welcomeIllustration from '../../assets/welcomeIllustration.svg';
import microsoftBadge from '../../assets/MS_Startups_Celebration_Badge_Dark.png';

const WelcomePage = () => {
	return (
		<div className={style.WelcomePage}>
			<div className={style.WelcomePageWrapper}>
				<div className={style.WelcomeContainer}>
					<span className={style.HeadingText}>Calcium</span>
					<span className={style.DescriptionText}>Create. Track. Share</span>

					<img src={welcomeIllustration} alt="Welcome illustration" className={style.WelcomeIllustration} />
				</div>

				<div className={style.ButtonsContainer}>
					<Link to='/signup'>
						<PrimaryButton text='Sign Up' onClick={() => { }} />
					</Link>

					<Link to='/login'>
						<SecondaryButton text='Log In' onClick={() => { }} />
					</Link>
				</div>

				<img src={microsoftBadge} alt="Microsoft Badge" className={style.MicrosoftBadge} />

				<a href="https://www.producthunt.com/posts/calcium?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-calcium" target="_blank" rel="noopener noreferrer">
					<img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=441617&theme=neutral" alt="Calcium - Create, track, and share workout routines. | Product Hunt" style={{ width: '250px', height: '54px' }} />
				</a>
			</div>
		</div>
	);
}

export default WelcomePage;
