import React from 'react';
import style from './ChoiceCard.module.css';

const ChoiceCard = props => {
	return (
		<div className={style.ChoiceCard} onClick={props.click}>
			<img src={props.icon} alt="" className={style.ChoiceIcon}/>

			<div className={style.ChoiceTextWrapper}>
				<span className={style.ChoiceHeading}>{props.heading}</span>

				<span className={style.ChoiceText}>
					{props.text}
				</span>
			</div>
		</div>
	)
}

export default ChoiceCard;