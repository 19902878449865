import React, { useState, useCallback, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import spinner from '../../assets/Spinner.svg'
import { generateWorkoutRoutines } from '../../utils/generateRoutines';

import {
	useUser,
} from "@clerk/clerk-react";

import { clerkClient } from "@clerk/nextjs/server";

import style from './Home.module.css';
import StrydCardSmall from '../../components/StrydCardSmall/StrydCardSmall';
import FriendCard from '../../components/FriendCard/FriendCard';
import ChallengeCard from '../../components/ChallengeCard/ChallengeCard';
import ProfileImageLinkUpdater from '../../updateImages';
import QuickAccessCard from '../../components/QuickAccessCard/QuickAccessCard';

import PlayIcon from '../../assets/playIcon.svg';
import peopleIcon from '../../assets/peopleIcon.svg';
import profileIcon from '../../assets/profileIcon.svg';
import plusIcon from '../../assets/PlusIcon.svg';
import AIIcon from '../../assets/AIIcon.svg';
import { UIContext } from '../../contexts/UIContext';
import useUpdateUIContext from '../../hooks/useUpdateUIContext';



const DEFAULT_USER_ID = "user_2PSVExD2Jze2iGYg75qSShJe8If";

const Home = () => {

	const { user, isLoaded } = useUser();
	const navigate = useNavigate();
	const { globalUIState, setGlobalUIState } = useContext(UIContext);

	const API_URL = process.env.REACT_APP_API_URL;
	const [stryds, setStryds] = useState({});
	const [dbUser, setDbUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [routines, setRoutines] = useState(null);
	const [people, setPeople] = useState(null);

	const getPeople = useCallback(() => {
		const userId = user?.id || DEFAULT_USER_ID;
		axios.get(`${API_URL}/recommendations/people/${userId}`)
			.then(res => setPeople(res.data))
			.catch(err => console.log('Error fetching people:', err.message));
	}, [API_URL, user]);

	const getRoutines = useCallback(() => {
		const userId = user?.id || DEFAULT_USER_ID;
		axios.get(`${API_URL}/recommendations/routines/${userId}`)
			.then(res => setRoutines(res.data))
			.catch(err => console.log('Error fetching routines:', err.message));
	}, [API_URL, user]);

	const getStryds = useCallback(() => {
		if (user?.id) { // Assuming stryds are user-specific
			axios.get(`${API_URL}/stryds/${user.id}`)
				.then(res => setStryds(res.data))
				.catch(err => console.log("Error:", err));
		}
	}, [API_URL, user]);

	const getUser = useCallback(() => {
		if (user?.id && !dbUser[0]) {
			axios.get(`${API_URL}/users/${user.id}`)
				.then(res => setDbUser(res.data))
				.catch(err => console.log("Error:", err.message));
		}
	}, [API_URL, dbUser, user]);

	useEffect(() => {
		if (isLoaded) {
			getPeople();
			getRoutines();
			if (user) {
				getStryds();
				getUser();
			}
			setLoading(false);
			// UpdateUIContext(0, 'test')	
		}
	}, [isLoaded, user, getPeople, getRoutines, getStryds, getUser]);

	if (!isLoaded || loading) {
		return <span>Loading...</span>;
	}



	return (
		<div className={style.HomePage}>
			{/* <button onClick={() => {
				resend.emails.send({
					from: 'onboarding@resend.dev',
					to: 'emmayodayo@gmail.com',
					subject: 'Hello World',
					html: '<p>Congrats on sending your <strong>first email</strong>!</p>'
				});
				console.log('hey email')
			}
			}>
				test
			</button> */}

			{!loading ? (
				<div className={style.HomePageContent}>
					<div className={style.CardsWrapper}>
						<span className={style.CardsTitle}>
							Quick Access
						</span>

						<div className={style.QuickActionsWrapper}>
							<Link to={`/profile-page`}>
								<QuickAccessCard
									title='Your routines'
									color='#786F34'
									icon={profileIcon}
									textColor='#858383'
									hoverColor="#D4C66E"
									hoverTextColor="#ffffff"
								/>
							</Link>

							<Link to='/interests'>
								<QuickAccessCard
									title='Generate routine'
									color='#3b4369'
									icon={AIIcon}
									textColor='#858383'
									hoverColor="#879af4"
									hoverTextColor="#ffffff"
								/>
							</Link>

							<Link to='/add-routine'>
								<QuickAccessCard
									title='Create routine'
									color='#55693A'
									icon={plusIcon}
									hoverColor='#B7ED6F'
								/>
							</Link>

							<Link to='/meals'>
								<QuickAccessCard
									title='Meals - preview'
									color='#243A3B'
									icon={peopleIcon}
									hoverColor='#568A8C'
								/>
							</Link>
						</div>
					</div>

					<div className={style.HomeCards}>
						<div className={style.CardsWrapper}>
							<span className={style.CardsTitle}>
								Explore routines based on your interests
							</span>

							<div>
								{routines !== undefined ? (
									<div>
										{Array.isArray(routines) && routines.length > 0 ? (
											<div className={style.CardsContainer}>
												{routines.slice(0, 4).map(routine => (
													<Link key={routine.userID} to={`/stryd/${routine.userID}/${routine.routine.routineName}`}>
														<StrydCardSmall
															name={routine.routine.routineName}
															time={routine.routine.estimatedTimeMinutes}
															creator={routine.username}
															userID={routine.userID}
														/>
													</Link>
												))}
											</div>
										) : (
											<span className={style.EmptyText}>
												No routines available
											</span>
										)}
									</div>
								) : (
									<div>
										<img src={spinner} alt="" />
									</div>
								)}
							</div>
						</div>

						<div className={style.CardsWrapper}>
							<span className={style.CardsTitle}>
								Suggested People
							</span>

							<div>
								{people ? (
									<div className={style.CardsContainer}>
										{people.slice(0, 4).map(person => (
											<Link to={`/profile/${person.userID}`}>
												<FriendCard
													username={person.username}
													id={person.userID}
													profileImage={person.profileImageLink}
												/>
											</Link>
										))}
									</div>
								) : (
									<img src={spinner} alt="" />
								)}
							</div>
						</div>
					</div>

					{/* <ProfileImageLinkUpdater /> */}
				</div>
			) : (
				<span className={style.LoadingText}>Loading...</span>
			)}
		</div>
	);
}

export default Home;