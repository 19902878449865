import React from 'react';
import style from './InterestChoiceCard.module.css';

const InterestChoiceCard = (props) => {
	return (
		<div
			className={`${style.InterestChoiceCardWrapper} ${props.selected ? style.Selected : ''}`} onClick={() => props.click()}>

			<span className={style.ChoiceTitle}>
				{props.Title}
			</span>

			<span className={style.ChoiceText}>
				{props.Text}
			</span>
		</div>
	)
}

export default InterestChoiceCard;