import React from 'react';
import { SignUp } from "@clerk/clerk-react";
import style from '../Login/Login.module.css';

const Signup = () => {
	// const { user } = useUser()
	// const navigate = useNavigate();

	return (
		<div className={style.LoginPage}>
			<div className={style.LoginForm}>
				<SignUp redirectUrl={'https://www.calcium.fitness/'} />
				{/* <SignUp redirectUrl={'http://localhost:3000/'} /> */}
			</div>
		</div>
	)
}

export default Signup;