import React, { useState } from 'react';
import style from './AddChallengeCard.module.css';
import ArrowIcon from '../../assets/arrowDownIcon.svg';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import MainDropdown from '../MainDropdown/MainDropdown';
import workouts from '../../utils/workouts.json'

import arrow from '../../assets/arrowDownIcon.svg';
import WorkoutCard from '../WorkoutCard/WorkoutCard';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

const AddChallengeCard = props => {
	const [checked, setChecked] = useState()
	const [openWorkouts, setOpenWorkouts] = useState(true)

	const [workoutAttributes, setWorkoutAttributes] = useState({
		workoutSets: 0,
		workoutReps: 0,
		workoutType: workouts.workouts[0].name
	})

	const handleAttributeChange = (attribute, value) => {
		setWorkoutAttributes(prevState => ({
			...prevState,
			[attribute]: value,
		}));
	};

	const handleIncrement = attribute => {
		handleAttributeChange(attribute, workoutAttributes[attribute] + 1);
	};

	const handleDecrement = attribute => {
		handleAttributeChange(
			attribute,
			Math.max(workoutAttributes[attribute] - 1, 0)
		);
	};

	const isFormValid = () => {
		const { workoutSets, workoutReps, workoutType } = workoutAttributes;

		// Check if workoutSets and workoutReps contain anything other than numbers
		if (!/^\d+$/.test(workoutSets) || !/^\d+$/.test(workoutReps)) {
			return false;
		}

		return workoutSets > 0 && workoutReps > 0 && workoutType !== '';
	};

	const workoutArray = Array.from({ length: workoutAttributes.sets });

	const handleRepsChange = event => {
		const input = event.target.value;
		const numericValue = input.replace(/[^0-9]/g, '');
		setWorkoutAttributes(prevState => ({
			...prevState,
			workoutReps: numericValue
		}));
	};


	const handleWorkoutNameChange = selectedValue => {
		setWorkoutAttributes({ ...workoutAttributes, workoutType: selectedValue });
	}

	const handleSubmit = () => {
		props.addRoutine(workoutAttributes);
	};


	return (
		<div className={style.AddChallengeCard}>

			<div
				className={style.AddChallengeCardWrapper}>
				<div className={style.AddChallengeCardContent}>
					<MainDropdown
						dataArray={workouts.workouts}
						change={handleWorkoutNameChange}
					/>


					<div className={style.WorkoutInputWrapper}>
						<div className={style.InputContainer}>
							<button
								className={style.InputButton}
								onClick={() => handleDecrement('workoutSets')}>
								-
							</button>

							<input
								name=""
								value={workoutAttributes.workoutSets}
								type='number'
								id=""
								placeholder='sets'
								color=''
								className={style.WorkoutInput}
								onChange={event =>
									handleAttributeChange('workoutSets', Math.max(0, parseInt(event.target.value) || 0))
								} />
							<span className={style.WorkoutType}>
								sets
							</span>

							<button
								className={style.InputButton}
								onClick={() => handleIncrement('workoutSets')}>
								+
							</button>
						</div>

						<div className={style.InputContainer}>
							<button
								className={style.InputButton}
								onClick={() => handleDecrement('workoutReps')}
							>
								-
							</button>

							<input
								name=""
								id=""
								type="number"
								placeholder="reps"
								className={style.WorkoutInput}
								value={workoutAttributes.workoutReps}
								onChange={event =>
									handleAttributeChange(
										'workoutReps',
										Math.max(0, parseInt(event.target.value) || 0)
									)
								}
							/>
							<span className={style.WorkoutType}>
								reps
							</span>


							<button
								className={style.InputButton}
								onClick={() => handleIncrement('workoutReps')}
							>
								+
							</button>
						</div>
					</div>

				</div>

				<SecondaryButton
					text='Add'
					click={handleSubmit}
					disabled={!isFormValid()}
				/>
			</div>

			<div className={style.WorkoutsCards}>
				{!openWorkouts ?
					workoutArray.map((_, index) => (
						<WorkoutCard name={workoutAttributes.workoutType} reps={workoutAttributes.workoutReps} />
					)) : null}
			</div>
		</div>
	)
};

export default AddChallengeCard;