import React from 'react';
import style from './FriendCard.module.css';
import { Link } from 'react-router-dom';
import profileIcon from '../../assets/profileIconTwo.svg'

const FriendCard = props => {
	return (
		<div className={style.FriendCardWrapper}>
			<div className={style.FriendInfo}>
					<img
						src={props.profileImage}
						alt=""
						className={style.ProfileImage}
					/>

				<span className={style.FriendUsername}>
					{props.username}
				</span>
			</div>

			<span className={style.FriendPoints}>

			</span>
			{/* <span className={style.FriendIcon}>+</span> */}
		</div>
	)
}

export default FriendCard;