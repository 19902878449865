const rankData = [{
		rank: 'Mortal',
		minScore: 0,
		maxScore: 800
	},
	{
		rank: 'Initiate',
		minScore: 801,
		maxScore: 2000
	},
	{
		rank: 'Soldier',
		minScore: 2001,
		maxScore: 4000
	},
	{
		rank: 'Hero',
		minScore: 4001,
		maxScore: 6500
	},
	{
		rank: 'Gladiator',
		minScore: 6501,
		maxScore: 10000
	},
	{
		rank: 'Centurion',
		minScore: 10001,
		maxScore: 15000
	},
	{
		rank: 'Titan',
		minScore: 15001,
		maxScore: 20500
	},
	{
		rank: 'Olympian',
		minScore: 20501,
		maxScore: 27000
	},
	{
		rank: 'Ultimate',
		minScore: 27001,
		maxScore: 35000
	},
	{
		rank: 'Deity',
		minScore: 35001,
		maxScore: Infinity
	}
];

export default rankData;