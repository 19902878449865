import React, { useState, useContext } from 'react';
import style from './InterestsPage.module.css';
import axios from 'axios';
import toast, { toastConfig } from 'react-simple-toasts';
import InterestChoiceCard from '../../components/InterestChoiceCard/InterestChoiceCard';
import { UserContext } from '../../contexts/UserContext';

import {
	useUser,
} from "@clerk/clerk-react";
import { Link, useNavigate } from 'react-router-dom';

import Checkbox from '../../components/CheckBox/CheckBox';
import RadioButton from '../../components/RadioButton/RadioButton';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import FormInput from '../../components/FormInput/FormInput';

const interestsArray =
	['Running', 'Home Workouts', 'Swimming', 'Lifting', 'Yoga', 'Cardio', 'Boxing', 'Pilates', 'HIIT', 'CrossFit', 'Dance', 'Martial Arts', 'Functional Training', 'Cycling', 'TRX Training', 'Calisthenics'];

const equipmentArray = [
	"Dumbbells",
	"Treadmill",
	"Resistance Bands",
	"Barbell",
	"Kettlebell",
	"Pull-Up Bar",
	"Yoga Mat",
	"Rowing Machine",
	"Exercise Bike",
	"Medicine Ball",
	"Bench Press",
	"Jump Ropes",
	"Stability Ball",
	"Battle Ropes",
	"Cable Machine"
];


const ageRangeArray = ['13 - 17', '18 - 30', '31 - 50', '51+'];
const genderArray = ['Male', 'Female', 'Other']
const fitnessLevelArray = ['Beginner', 'Intermediate', 'Advanced'];
const styleArray = ['Short and Intense', 'Long and moderate'];
const durationArray = ['30 mins', '45mins', '1 hr'];
const API_URL = process.env.REACT_APP_API_URL;

toastConfig({ theme: 'dark' });

const InterestsPage = () => {
	const { user } = useUser()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState('');
	const [showEquipments, setShowEquipments] = useState(false);
	const { dbUser, setDbUser } = useContext(UserContext);
	const [selectedInterests, setSelectedInterests] = useState({
		type: 'bodyweight',
		workoutInterests: [],
		workoutEquipments: [],
		ageRange: '',
		gender: '',
		level: '',
		style: '',
		duration: '',
		goal: '',
		additionalText: ''
	});

	const isFormValid = () => {
		const { workoutInterests, level, style, duration, goal } = selectedInterests;
		return workoutInterests.length > 0 && level !== '' && style !== '' && duration !== '' && goal !== '';
	};

	const addInterests = () => {
		const valid = isFormValid(); // Check if the form is valid
		if (!valid) {
			toast('Some fields are empty, please check the fields marked as required.');
			return; // Prevent further execution if the form is invalid
		}

		setIsLoading(true);
		setLoadingStatus('loading');

		if (user) {
			axios
				.post(`${API_URL}/interests/${user.id}`, selectedInterests)
				.then(res => {
					console.log(res);
					setIsLoading(false);
					navigate('/generated-stryds');
				})
				.catch(err => {
					console.log(err);
					setIsLoading(false);
				});
		}
	};



	const handleInterestChange = (interest) => (isChecked) => {
		setSelectedInterests(prevState => {
			if (isChecked) {
				return { ...prevState, workoutInterests: [...prevState.workoutInterests, interest] };
			} else {
				return { ...prevState, workoutInterests: prevState.workoutInterests.filter(option => option !== interest) };
			}
		});
	};

	const handleEquipmentChange = (equipment) => (isChecked) => {
		setSelectedInterests(prevState => {
			if (isChecked) {
				return { ...prevState, workoutEquipments: [...prevState.workoutEquipments, equipment] };
			} else {
				return { ...prevState, workoutEquipments: prevState.workoutEquipments.filter(option => option !== equipment) };
			}
		});
	};

	const handleLevelChange = (level) => () => {
		setSelectedInterests(prevState => ({ ...prevState, level }));
	};

	const handleAgeRangeChange = (ageRange) => () => {
		setSelectedInterests(prevState => ({ ...prevState, ageRange }))
	}

	const handleGenderChange = (gender) => () => {
		setSelectedInterests(prevState => ({ ...prevState, gender }))
	}

	const handleStyleChange = (style) => () => {
		setSelectedInterests(prevState => ({ ...prevState, style }));
	};

	const handleDurationChange = (duration) => () => {
		setSelectedInterests(prevState => ({ ...prevState, duration }));
	};

	const handleGoalTextChange = event => {
		setSelectedInterests(prevState => ({ ...prevState, goal: event.target.value }));
	};

	const handleAdditionalTextChange = event => {
		setSelectedInterests(prevState => ({ ...prevState, additionalText: event.target.value }));
	};



	return (
		<div>
			{
				(dbUser.userPlan === 'calcium-monthly' || dbUser.userPlan === 'calcium-quarterly' || dbUser.userPlan === 'calcium-annually') ? (
				<>
					<h3 className={style.HeadingDescription}>We need some info so we can generate your custon routine!</h3>

					<div className={style.InterestsPageWrapper}>
						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>
								What kind of workouts are you interested in?
							</span>

							<div className={style.InterestsCardWrapper}>
								<InterestChoiceCard
									Title='Bodyweight'
									Text='Workout using just your body weight.'
									click={() => setSelectedInterests(prevState => ({ ...prevState, type: 'bodyweight' }))}
									selected={selectedInterests.type === 'bodyweight'}
								/>

								<InterestChoiceCard
									Title='With Equipment'
									Text='Enhance your workouts with equipment.'
									click={() => setSelectedInterests(prevState => ({ ...prevState, type: 'equipments' }))}
									selected={selectedInterests.type === 'equipments'}
								/>

							</div>
						</div>

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>How old are you?</span>
							<div className={style.InterestSelection}>
								{
									ageRangeArray.map((ageRange, index) =>
										<RadioButton
											key={ageRange}
											label={ageRange}
											value={ageRange}
											name='age'
											isChecked={selectedInterests.ageRange === ageRange}
											onRadioChange={handleAgeRangeChange(ageRange)}
										/>
									)
								}
							</div>
						</div>

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>Gender</span>
							<div className={style.InterestSelection}>
								{
									genderArray.map((gender, index) =>
										<RadioButton
											key={gender}
											label={gender}
											value={gender}
											name='gender'
											isChecked={selectedInterests.gender === gender}
											onRadioChange={handleGenderChange(gender)}
										/>
									)
								}
							</div>
						</div>

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>
								What kind of workouts are you interested in? - required
							</span>

							<div className={style.InterestsWrapper}>
								{interestsArray.map((interest, index) => (
									<Checkbox
										key={interest}
										label={interest}
										isChecked={selectedInterests.workoutInterests.includes(interest)}
										onCheckboxChange={handleInterestChange(interest)}
									/>
								))}
							</div>
						</div>

						{
							selectedInterests.type === 'equipments'
								?
								<div className={style.InterestSelectionWrapper}>
									<span className={style.QuestionHeading}>
										What kind of equipment do you have?
									</span>

									<div className={style.InterestsWrapper}>
										{equipmentArray.map((equipment, index) => (
											<Checkbox
												key={equipment}
												label={equipment}
												isChecked={selectedInterests.workoutEquipments.includes(equipment)}
												onCheckboxChange={handleEquipmentChange(equipment)}
											/>
										))}
									</div>
								</div>
								: null
						}

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>Fitness level - required</span>
							<div className={style.InterestSelection}>
								{
									fitnessLevelArray.map((level, index) =>
										<RadioButton
											key={level}
											label={level}
											value={level}
											name='levels'
											isChecked={selectedInterests.level === level}
											onRadioChange={handleLevelChange(level)}
										/>
									)
								}
							</div>
						</div>

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>Workout style - required</span>
							<div className={style.InterestSelection}>
								{
									styleArray.map((style, index) =>
										<RadioButton
											key={style}
											label={style}
											value={style}
											name='styles'
											isChecked={selectedInterests.style === style}
											onRadioChange={handleStyleChange(style)}
										/>
									)
								}
							</div>
						</div>

						<div className={style.InterestSelectionWrapper}>
							<span className={style.QuestionHeading}>How long do you want your workouts to be? - required</span>
							<div className={style.InterestSelection}>
								{
									durationArray.map((duration, index) =>
										<RadioButton
											key={duration}
											label={duration}
											value={duration}
											name='durations'
											isChecked={selectedInterests.duration === duration}
											onRadioChange={handleDurationChange(duration)}
										/>
									)
								}
							</div>
						</div>

						<FormInput
							HeadingText='What are your current workout goals? - required'
							change={handleGoalTextChange}
							width={window.innerWidth < 500 ? '300px' : '480px'}
							showIsEmpty={true}
						/>

						<FormInput
							HeadingText='Anything else you want to share? (health concerns, specific workouts, target muscles etc.)'
							change={handleAdditionalTextChange}
							width={window.innerWidth < 500 ? '300px' : '480px'}
							showIsEmpty={true}
						/>

						<div className={style.ButtonsWrapper}>
							<PrimaryButton
								text='Proceed'
								click={addInterests}
							// disabled={!isFormValid()}
							/>

						</div>
					</div>
				</>
			) : (
				<div className={style.UpgradeInfoWrapper}>
					<span>
						Upgrade to Calcium Plus to generate your routine.
					</span>

					<Link to='/upgrade'>
						<SecondaryButton
							text='Upgrade'
							click={() => null}
						/>
					</Link>
				</div>
			)
			}
		</div >
	)
}

export default InterestsPage