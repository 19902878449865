import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import style from './People.module.css';
import FriendCard from '../../components/FriendCard/FriendCard';
import { Link, useParams } from 'react-router-dom';
import spinner from '../../assets/Spinner.svg'
import FormInput from '../../components/FormInput/FormInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import toast from 'react-simple-toasts';

import {
	useUser,
} from "@clerk/clerk-react";

import { clerkClient } from "@clerk/nextjs/server";



const API_URL = process.env.REACT_APP_API_URL;

const People = () => {
	const { user } = useUser()
	const { userID } = useParams()
	const DEFAULT_USER_ID = "user_2PSVExD2Jze2iGYg75qSShJe8If";


	const [people, setPeople] = useState(null);
	const [searchInput, setSearchInput] = useState('')


	const getPeople = useCallback(() => {
		const userId = user?.id || DEFAULT_USER_ID; // Use default user ID if user or user.id is not available

		axios.get(`${API_URL}/recommendations/people/${userId}`)
			.then(res => setPeople(res.data))
			.catch(err => console.log('error', err.message));

		console.log('get people');
	}, [user])

	const handleSearchTtextChange = event => {
		setSearchInput(event.target.value);
	}

	const getSearchResults = () => {
		if (searchInput === '') {
			toast('Search field is empty')
			return
		}

		setPeople(null)
		axios.get(`${API_URL}/search/people/${searchInput}`)
			.then(res => setPeople(res.data))
			.catch(err => console.log('error', err.message));
		console.log('got results for' + searchInput)
	}

	useEffect(() => {
		getPeople()
	}, [getPeople])


	console.log(async () => await clerkClient.users.getUser(userID))

	return (
		<div>
			<div className='SearchContainer'>
				<FormInput
					placeholder='Search for usernames'
					change={handleSearchTtextChange}
					width={window.innerWidth < 500 ? '240px' : '480px'}
					onEnter={() => getSearchResults()}
				/>

				<PrimaryButton
					text='Search'
					click={() => getSearchResults()}
					width='120px'
				/>
			</div>

			{people ? (
				people.length > 0 ? ( // Check if people array has items
					<div className={style.CardsContainer}>
						{people.map(person => (
							<Link key={person.userID} to={`/profile/${person.userID}`}>
								<FriendCard
									key={person._id}
									username={person.username}
									profileImage={person.profileImageLink}
								/>
							</Link>
						))}
					</div>
				) : (
					<p>No people found</p>
				)
			) : (
				<img src={spinner} alt="" />
			)}
		</div>
	);

}

export default People;