import React from 'react';
import style from './MealPlan.module.css';

const MealPlan = ({ dayName, meals }) => {
	return (
		<div className={style.MealPlanWrapper}>
			<h4 className={style.MealDay}>{dayName}</h4>
			<div className={style.MealPlanWrapper}>
				{Object.keys(meals).map((mealType) => (
					<div key={mealType} className={style.Meals}>
						<span className={style.MealType}>{mealType}</span>
						<span className={style.MealName}>{meals[mealType]}</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default MealPlan;