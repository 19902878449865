// fetch routines hereconst axios = require('axios');
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL;


export const generateMeals = async (llmPrompt) => {
	const url = `${API_URL}/generate/generate-meal-plan`;
	const prompt = llmPrompt;

	try {
		const response = await axios.post(url, {
			prompt
		});
		console.log(response.data);
		return response.data
	} catch (error) {
		console.error('Error generating workout routine:', error);
	}
};


// Add a feature to delete routine.